.NotFound {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px auto;
  width: 90%;
}

.NotFoundIcon {
  font-size: 4em;
}

.NotFound h2 {
  margin-bottom: 0;
  text-align: center;
}
